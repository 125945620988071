import { getDeleteJourneyApiURL, getOrgJourneysApiURL, sessionsApiURL } from "./ApiManager/List";
import { responseVerification } from "./ApiManager/Methods";
import { API_METHODS } from "./ApiManager/ResponseCodes";
import API_CALL from "./ApiManager";
import { getFilterAsArray } from "../utils/Utils";
import { getJourneyApiURL } from "./ApiManager/List";

export const JOURNEY_DEFAULT_NAME = 'game-only-payment';

/*############################################__JOURNEY__############################################*/
export const journeyTypes = [
	{
		id: 1,
		label: "Game only",
		value: "game-only",
	},
	{
		id: 6,
		label: "Game only minimal steps",
		value: "game-only-minimal",
	},
	{
		id: 2,
		label: "Game only with Payment",
		value: "game-only-payment",
	},
	{
		id: 3,
		label: "Game and Survey(s)",
		value: "game-survey",
	},
	{
		id: 4,
		label: "Game and Survey(s) with Payment",
		value: "game-survey-payment",
	},
	{
		id: 5,
		label: "Game and Survey(s) minimal steps",
		value: "game-survey-minimal",
	}
]

export const getOrgJourneyData = (accessToken, orgId, groupId, offset, sizePerPage, sortField, sortOrder, filters, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = getOrgJourneysApiURL(orgId, groupId,
		offset, sizePerPage,
		sortField, sortOrder,
		JSON.stringify(getFilterAsArray(filters)))
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}


/**
 * First use journey from Group, then Org, and default if neither is set
 * @param {*} org 
 * @param {*} group 
 * @returns 
 */
export const getDefaultJourneyTypeName = (org, group) => {
	if (group?.journeyTypeName?.length > 0) {
		return group?.journeyTypeName
	} else if (org?.journeyTypeName?.length > 0) {
		return org?.journeyTypeName
	}
	return JOURNEY_DEFAULT_NAME
}

export const addNewJourney = (accessToken, orgId, groupId, payload, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = getJourneyApiURL(orgId, groupId);
	let method = API_METHODS.POST;
	API_CALL(accessToken, method, url, payload, (successData) => {
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData);
	});
}

export const editJourney = (accessToken, orgId, groupId, journeyId, data, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = getJourneyApiURL(orgId, groupId, journeyId)
	let method = API_METHODS.PUT
	API_CALL(accessToken, method, url, data, (successData) => {
		successCallback(successData);
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const getJourneyDetail = (accessToken, orgId, groupId, journeyId, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = getJourneyApiURL(orgId, groupId, journeyId)
	let method = API_METHODS.GET
	API_CALL(accessToken, method, url, null, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}

export const deleteJourneyData = (accessToken, orgId, groupId, journeyId, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = getDeleteJourneyApiURL(orgId, groupId, journeyId)
let method = API_METHODS.DELETE
	API_CALL(accessToken, method, url, null, (successData) => {
			if(isVerificationRequired && !responseVerification(successData.statusCode)) {
					errorCallback(successData.statusCode)
		return            
			}
			successCallback(successData)
	}, (errorData) => {
			errorCallback(errorData)
	});
}

export const initiateNewAssessmentSession = (accessToken, session, successCallback, errorCallback, isVerificationRequired = true) => {
	let url = sessionsApiURL;
	let method = API_METHODS.POST
	API_CALL(accessToken, method, url, session, (successData) => {
		if (isVerificationRequired && !responseVerification(successData.statusCode)) {
			errorCallback(successData.statusCode)
			return
		}
		successCallback(successData)
	}, (errorData) => {
		errorCallback(errorData)
	});
}